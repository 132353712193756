import { defineStore } from 'pinia';
import { computed, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { dialog } from '@/common/util';
import avaliacaoService from '@/services/avaliacao.service';
export const useAvaliacaoStore = defineStore('avaliacoes', () => {
    const { t } = useI18n();
    const list = ref([]);
    const status = reactive({
        getAll: 'initial',
        create: 'initial',
        update: 'initial',
        delete: 'initial',
        getById: 'initial',
        bonds: 'initial',
        prepare: 'initial',
    });
    const loading = computed(() => Object.values(status).some(el => el === 'loading'));
    const methodStatus = computed(() => (method, sts) => {
        if (Array.isArray(method))
            return method.some((el) => status[el] === sts);
        return status[method] === sts;
    });
    const clear = () => {
        list.value.splice(0, list.value.length);
    };
    const getAll = async () => {
        status.getAll = 'loading';
        clear();
        try {
            const { data, success } = await avaliacaoService.get();
            if (success) {
                // Normaliza os atributos da avaliação
                list.value = data.map((exam) => fmAvaliacaoResponse(exam));
            }
            status.getAll = 'success';
        }
        catch (error) {
            status.getAll = 'error';
        }
    };
    const getById = async (id) => {
        status.getById = 'loading';
        try {
            const { data, success } = await avaliacaoService.get(id);
            if (success) {
                status.getById = 'success';
                return {
                    id: data.id,
                    title: data.titulo,
                    observation: data.observacao,
                    type: data.tipoDeAvaliacao,
                    duration: data.duracaoDaAvaliacao,
                    skillsIds: data.competenciasIds,
                    score: data.pontuacaoMinima,
                    canRetry: data.permitirNovasTentativas,
                    maxRetries: data.maximoTentativas,
                    maxQuestions: data.maximoQuestoes,
                    shuffleQuestions: data.embaralharQuestoes,
                    shuffleOptions: data.embaralharAlternativas,
                    displayAnswers: data.exibirCorretaseErradas,
                    showTimer: data.exibirTimer,
                    questions: data.questoes.map((questao) => ({
                        id: questao.id,
                        type: questao.tipoDeQuestao,
                        statement: questao.enunciado,
                        scale: questao.escala,
                        score: questao.pontuacaoMaxima,
                        showFeedback: questao.exibirFeedback,
                        feedback: {
                            right: questao.feedbackAcerto,
                            wrong: questao.feedbackErro,
                        },
                        order: questao.ordem,
                        imageLink: questao.anexo,
                        options: questao.alternativas.map((option) => ({
                            id: option.id,
                            text: option.descricao || '',
                            isTrue: option.correta,
                            questionId: option.questaoId
                        })),
                        supportedFiles: {
                            audio: questao.tipoUpload?.audio,
                            camera: questao.tipoUpload?.camera,
                            document: questao.tipoUpload?.documento,
                            image: questao.tipoUpload?.imagem,
                            microphone: questao.tipoUpload?.microfone,
                            screen: questao.tipoUpload?.tela,
                            video: questao.tipoUpload?.video
                        },
                        answerType: questao.tipoResposta?.toLowerCase() || ''
                    })),
                    active: data.active,
                    categoryId: data.categoriaId,
                    categoryName: data.categoriaNome
                };
            }
        }
        catch (error) {
            status.getById = 'error';
        }
    };
    const formatData = (dt) => ({
        titulo: dt.title,
        observacao: dt.observation,
        tipoDeAvaliacao: dt.type,
        duracaoDaAvaliacao: dt.showTimer ? dt.duration?.split(':').length > 2 ? dt.duration : `${dt.duration || '00'}:00` : null,
        competenciasIds: dt.skillsIds,
        permitirNovasTentativas: dt.canRetry,
        maximoTentativas: dt.maxRetries,
        maximoQuestoes: dt.maxQuestions,
        pontuacaoMinima: dt.score,
        embaralharQuestoes: dt.shuffleQuestions,
        embaralharAlternativas: dt.shuffleOptions,
        exibirCorretaseErradas: dt.displayAnswers,
        exibirTimer: dt.showTimer,
        questoes: dt.questions.map((question) => (question.id ?? delete question.id) && ({
            id: question.id,
            tipoDeQuestao: question.type,
            enunciado: question.statement,
            escala: question.scale,
            ordem: question.order,
            exibirFeedback: question.showFeedback,
            feedbackAcerto: question.feedback.right,
            feedbackErro: question.feedback.wrong,
            anexo: question.imageLink,
            imagem: (question?.image ? ({
                data: question?.image?.split(',').at(-1),
                mime: question?.image?.split(':').at(-1).split(';').at(0)
            }) : null),
            alternativas: question.options.map((option) => ({
                id: option.id,
                descricao: option.text || '',
                correta: option.isTrue,
                questaoId: option.questionId
            })),
            pontuacaoMaxima: Number(question.score) || 1,
            tipoResposta: question.type === 'Pratica' && question.answerType ? question.answerType : null,
            tipoUpload: question.answerType === 'upload' ? {
                audio: question.supportedFiles?.audio,
                camera: question.supportedFiles?.camera,
                documento: question.supportedFiles?.document,
                imagem: question.supportedFiles?.image,
                microfone: question.supportedFiles?.microphone,
                tela: question.supportedFiles?.screen,
                video: question.supportedFiles?.video
            } : null
        })),
        active: dt.active,
        categoriaId: dt.categoryId
    });
    const save = async (dt, id) => {
        const method = id ? 'update' : 'create';
        status[method] = 'loading';
        try {
            const { success, data } = id
                ? await avaliacaoService.update(id, { ...formatData(dt) })
                : await avaliacaoService.create({ ...formatData(dt), active: true });
            if (success)
                getAll();
            status[method] = 'complete';
            return data;
        }
        catch {
            status[method] = 'error';
            return false;
        }
    };
    const remove = async (avaliacao, force) => {
        if (!force) {
            return dialog.open({
                title: t('feedback.attention'),
                message: t('dialogs.delete.message', { type: t('labels.test').toLowerCase(), name: avaliacao.title }),
                complete: () => remove(avaliacao, true)
            });
        }
        try {
            status.delete = 'loading';
            const { success } = await avaliacaoService.remove(avaliacao.id);
            if (success)
                list.value.splice(list.value.findIndex(el => el.id === avaliacao.id), 1);
            status.delete = 'success';
        }
        catch (error) {
            status.delete = 'error';
        }
    };
    const bonds = async (avaliacaoId) => {
        status.bonds = 'loading';
        try {
            const { data } = await avaliacaoService.getBonds(avaliacaoId);
            status.bonds = 'success';
            return data;
        }
        catch {
            status.bonds = 'error';
        }
    };
    const prepare = async (opt) => {
        status.prepare = 'loading';
        try {
            const result = avaliacaoService.getInfo(opt);
            status.prepare = 'success';
            return result;
        }
        catch (error) {
            status.prepare = 'error';
        }
    };
    const fmAvaliacaoResponse = (data) => {
        return {
            id: data.id,
            title: data.titulo,
            observation: data.observacao,
            type: data.tipoDeAvaliacao,
            duration: data.duracaoDaAvaliacao,
            skillsIds: data.avaliacoesCompetencias,
            score: 0,
            canRetry: data.permitirNovasTentativas,
            maxRetries: data.maximoTentativas,
            maxQuestions: data.maximoQuestoes,
            shuffleQuestions: data.embaralharQuestoes,
            shuffleOptions: data.embaralharAlternativas,
            displayAnswers: data.exibirCorretaseErradas,
            showTimer: data.exibirTimer,
            questions: data.questoes,
            active: data.active,
            origin: data.origem,
            categoryId: data.categoriaId,
            categoryName: data.categoriaNome
        };
    };
    return {
        list,
        loading,
        status,
        methodStatus,
        //
        getAll,
        getById,
        save,
        remove,
        bonds,
        prepare
    };
});
